import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";
import { leaf } from "./assets";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from 'yup';
const initialValue = {
  email:'',
  password:'',
  picked:false
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("You've entered invalid email")
    .required('Email is required'),
 
  password: Yup.string()
    .required('Password is required'),
});

const StyledWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  overflowX:"hidden",
  '@media (max-width: 900px)': {
    flexDirection: "column",
    marginTop: "30px",
    alignItems: "end"
  },
  "& .boxContainer":{
    margin: "140px auto auto auto",
    '@media (max-width: 1000px)': {
      margin: "100px auto auto auto",
    },
    '@media (max-width: 750px)': {
      margin: "20px auto auto auto",
    },
  },
  "& .heading": {
    fontSize: "24px",
    fontWeight: "700",
    color: "#000000",
    marginBottom: "10px"
  },
  "& .formContainer": {
    display: "flex",
    flexDirection: "column",
    width: "360px",
    '@media (max-width: 420px)': {
      width: "310px",
    },
  },
  "& .labelTag": {
    fontSize: "16px",
    fontWeight: "700",
    color: "#334155",
    marginBottom: "5px",
    fontFamily:"Inter, sans-serif"
  },
  "& .inputTags": {
    paddingLeft: "5px",
    height: "56px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
  },
  "& .checkBox": {
    height: "33px",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .options": {
    fontSize: "16px",
    fontWeight: "400",
    color: "#0F172A",
    marginLeft: "5px"
  },
  "& .forgotField": {
    fontSize: "16px",
    fontWeight: "600",
    color: "#6200EA",
    textDecoration: "none",
    fontFamily:"Inter,sans-serif"
  },
  "& .loginBtn": {
    border: "none",
    borderRadius: "8px",
    height: "56px",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700"
  },
  "& .alreadyAccountHeading": {
    fontSize: "16px",
    fontWeight: "400",
    color: "#0F172A",
    marginTop: "10px"
  },
  "& .loginNavigation": {
    fontWeight: "700",
    fontSize: "16px",
    color: "#6200EA",
    textDecoration: "none"
  },
  "& .loginBg": {
    display:"flex",
    justifyContent:"end",
    height:"100vh",
    width: "30%",
  },
  "& .leaf-bg": {
    '@media (max-width: 900px)': {
        width: "290px",
    },
  },
  "& .errorMessage": {
    height: "42px",
    backgroundColor: "#FEE2E2",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#DC2626",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "4px solid #DC2626"
  }

});
 
const BpIcon = styled('span')({
  borderRadius: 5,
  width: 18,
  height: 18,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
});
 
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#6200EA',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
 });
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <StyledWrapper>
            <Box className="boxContainer">
                <Typography className="heading" >Login to CoffePerks</Typography>
                <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        this.emailAccountLogin(values)
                        resetForm({values:initialValue})
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                        dirty,
                    }) => (
                        <form className="formContainer" onSubmit={handleSubmit}>
                          {this.state.errorMessage !== '' && (
                            <div className="errorMessage">{this.state.errorMessage}</div>
                          )}
                            <label className="labelTag">Email</label>
                            <TextField
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                sx={{ marginBottom: "15px", ".MuiOutlinedInput-root":{borderRadius:'8px'}}}
                            />
                            
                            <label className="labelTag">Password</label>
                            <TextField
                                type={this.state.showPassword ? 'text' : 'password'}
                                placeholder="Your password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                                sx={{ marginBottom: "15px", ".MuiOutlinedInput-root":{borderRadius:'8px'}}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.handleTogglePassword} edge="end">
                                                {this.state.showPassword ? <Visibility />  : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box className="checkBox">
                                <div>
                                    <Checkbox checked={values.picked}
                                        onChange={handleChange}
                                        onBlur={handleBlur} name="picked" value="rememberMe"
                                        sx={{ '&.MuiCheckbox-root':{padding:0},'&:hover': { bgcolor: 'transparent' } }}
                                        disableRipple
                                        color="default"
                                        checkedIcon={<BpCheckedIcon />}
                                        icon={<BpIcon />}
                                        inputProps={{ 'aria-label': 'Checkbox demo' }}
                                        {...this.props}
                                         />
                                        <span style={{fontFamily:"Inter,sans-serif",marginLeft:"5px",color:"#0F172A",fontSize:"14px", fontWeight:"400"}} >Remember me</span> 
                                </div>
                                <div className="options">
                               <Link className="forgotField" to={"/ForgotPassword"}>Forgot password?</Link>
                               </div>
                            </Box>
                            <button type="submit" className="loginBtn" style={{
                                background: (isValid && dirty) ? 'linear-gradient(99.09deg, #C399FF 2.64%, #6200EA 100%)' : '#CBD5E1',
                            }} >
                               Login
                            </button>
                        </form>
                    )}
                </Formik>
                <Typography className="alreadyAccountHeading" >Not a member? <Link to={"/EmailAccountRegistration"} className="loginNavigation">Create an account</Link></Typography>
            </Box>
            <Box className="loginBg"><img className="leaf-bg" src={leaf} alt="image" /></Box>
        </StyledWrapper>

      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});

import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import {styled} from "@mui/system";
import { Formik } from "formik";
import {
  TextField,
  Grid,
} from "@mui/material";
import * as Yup from 'yup';
const initialValue = {
  fullname:'',
  email:'',
  mobile_number:'',
  subject:'',
  description:''
}

const validationSchema = Yup.object({
  fullname: Yup.string()
    .required('Full name is required'),
  email: Yup.string()
    .email("Invalid email address")
    .required('Email address is required'),
  mobile_number: Yup.string()
    .required('Mobile number is required'),
  subject: Yup.string()
    .required('Subject is required'),
  description: Yup.string()
    .required('Message body is required'),
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <Wrapper>
        <div className="titleContainer" >
          <ChevronLeftOutlinedIcon sx={{backgroundColor:"#F1F5F9", height:"56px", width:"56px",borderRadius:"8px"}} />
          <Typography className="title" >Contact Us Form</Typography>
        </div>
        <div>
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              this.handleContactFormSubmit(values)
              resetForm({ values: initialValue })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              dirty,
            }) => (
              <form className="formContainer" onSubmit={handleSubmit}>
                {this.renderSuccessMessage()}
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} >
                    <label className="labelTag">Full name</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="fullname"
                      placeholder="Your full name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullname}
                      {...this.getTextFieldErrorProps('fullname', touched, errors)}
                      sx={{marginTop:"5px",".MuiOutlinedInput-root": { borderRadius: '8px' } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} >
                    <label className="labelTag">Email</label>
                    <TextField
                      fullWidth 
                      type="email"
                      name="email"
                      placeholder="Your email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      {...this.getTextFieldErrorProps('email', touched, errors)}
                      sx={{ marginTop:"5px",".MuiOutlinedInput-root": { borderRadius: '8px' } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <label className="labelTag">Mobile number</label>
                    <TextField
                      fullWidth
                      type="number"
                      name="mobile_number"
                      placeholder="Mobile number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile_number}
                      {...this.getTextFieldErrorProps('mobile_number', touched, errors)}
                      sx={{
                        marginTop: "5px", ".MuiOutlinedInput-root": { borderRadius: '8px' },
                        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0,
                        },
                        '& input[type=number]': {
                          '-moz-appearance': 'textfield',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <label className="labelTag">Subject</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      {...this.getTextFieldErrorProps('subject', touched, errors)}
                      sx={{marginTop:"5px",".MuiOutlinedInput-root": { borderRadius: '8px' } }}
                    />
                  </Grid>
                <Grid item xs={12}>
                  <label className="labelTag">Description</label>
                    <TextField
                      fullWidth
                      multiline
                      rows={5}
                      name="description"
                      placeholder="Description of Inquiry"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      {...this.getTextFieldErrorProps('description', touched, errors)}
                      sx={{
                        marginTop: '5px',
                        '.MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                      }}
                    />
                </Grid>
                </Grid>
                <Grid container marginTop={"30px"} marginBottom={"80px"} spacing={2}>
                  <Grid item xs={12} md={10}>
                  <button type="submit" className="loginBtn" style={{
                   background: this.getButtonGradient(isValid, dirty),
                  }} >
                    Submit
                  </button>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <button className="cancel-btn" >Cancel</button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </Wrapper>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  width:"90%",
  margin:"0 auto",
  "& .titleContainer":{
    display:"flex",
    alignItems:"center",
    gap:"30px",
    marginTop:"40px"
  },
  "& .title":{
    fontSize:"24px",
    fontWeight:"700",
    color:"#000000"
  },
  "& .formContainer":{
    margin:"50px auto 0 auto",
    width:"80%",
  },
  "& .loginBtn": {
    width:"100%",
    border: "none",
    borderRadius: "8px",
    height: "56px",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700",
    cursor:"pointer"
  },
  "& .cancel-btn": {
    width:"100%",
    border: "none",
    borderRadius: "8px",
    height: "56px",
    color: "#000000",
    fontSize: "16px",
    fontWeight: "700",
    backgroundColor:"#CBD5E1",
    cursor:"pointer"
  },
  "& .labelTag": {
    fontSize: "16px",
    fontWeight: "700",
    color: "#334155",
    fontFamily:"Inter,sans-serif",
  },
  "& .text-area":{
    width:"100%",
    height:"170px",
    marginTop:"5px",
    borderRadius:"8px",
    borderColor:"#CBD5E1"
  },
  "& .errorMessage": {
    height: "42px",
    backgroundColor: "#FEE2E2",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#DC2626",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "4px solid #DC2626",
    marginBottom:"10px"
  },
  "& .successMessage":{
    height: "42px",
    backgroundColor: "#D1FAE5",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#059669",
    display: "flex",
    alignItems: "center",
    borderLeft: "4px solid #059669",
    marginBottom:"10px",
    paddingLeft:"10px"
  }
});
// Customizable Area End

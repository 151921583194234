import React from "react";

// Customizable Area Start
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
  } from "@mui/material";
  import { styled } from "@mui/material/styles";
  import { Formik } from "formik";
  import { Link } from "react-router-dom";
  import {leaf,avatar} from"./assets";
  import { Visibility, VisibilityOff } from '@mui/icons-material';
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
  import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
  import * as Yup from 'yup';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
  import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
  import dayjs from "dayjs";
  
  const initialValue = {
      user_name:'',
      email:'',
      date_of_birth: null,
      password:'',
      password_confirmation:'',
      profile_image:null as Blob | null,
      picked:false
  };
  
  const validationSchema = Yup.object({
      user_name: Yup.string()
        .required('Full name is required')
        .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)+$/, 'Full name is required.'),
      email: Yup.string()
        .email("You've entered invalid email")
        .required('Email is required'),
      date_of_birth: Yup.date()
        .required('Date of birth is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required')
        .matches(/[A-Z]/, 'Password must contain at least one capital letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special character'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Your password must be the same in both fields')
        .required('Confirm password is required'),
      picked: Yup.boolean()
        .oneOf([true], 'Accept Privacy Policy and Terms and Condtions'),
        profile_image: Yup.mixed()
          .test('fileType', 'Invalid file type', (value) => {
              if (!value) return true;
              return value.type === 'image/png' || value.type === 'image/jpg';
          }),
    });  
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start
   PasswordValidationChecklist = ({ password = '', visible = false }) => {
    const validations = [
      {
        id: 'capital',
        test: /[A-Z]/.test(password),
        text: 'At least one capital letter',
      },
      {
        id: 'lowercase',
        test: /[a-z]/.test(password),
        text: 'At least one lowercase letter',
      },
      {
        id: 'number',
        test: /\d/.test(password),
        text: 'At least one number',
      },
      {
        id: 'length',
        test: password.length >= 8,
        text: 'Minimum character length is 8 characters',
      },
    ];
  
    if (!visible) return null;
  
    return (
      <Box marginBottom="10px">
        {validations.map(({ id, test, text }) => (
          <Box key={id} display="flex" alignItems="center" gap={1}>
            {test ? (
              <CheckCircleIcon sx={{ width: 16, height: 16, color: 'green' }} />
            ) : (
              <DoDisturbOnIcon sx={{ width: 16, height: 16, color: 'red' }} />
            )}
            <Typography variant="body2" sx={{ color: '#000000', fontSize: '12px', fontWeight: '400' }}>
              {text}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };
  // Customizable Area End

  render() {
   // Customizable Area Start
    return (
        <StyledWrapper>
        <Box style={{ margin: "50px auto auto" }}>
            <Typography className="heading" >Sign up to CoffePerks</Typography>
            <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    this.newUserAccountCreation(values)
                    resetForm({values:initialValue})
                }}
                data-testid="signupForm"
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isValid,
                    dirty,
                }) => (
                    <form className="formContainer" data-testid="formContainer" onSubmit={handleSubmit}>
                            {this.renderErrorMessage(touched, errors, 'picked')}
                            {this.renderErrorMessage(touched, errors, 'image')}
                            {
                                this.state.fileName ? (

                                    <Box
                                       className="uploadName"
                                    >
                                        <Typography
                                            sx={{ flexGrow: 1, fontSize: '14px', color: '#000', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {this.state.fileName}
                                        </Typography>
                                        <CloseOutlinedIcon  onClick={this.handleClose} sx={{color:"#94A3B8"}}  />
                                    </Box>
                                ) : (
                                    <Box
                                        className="fileUpload"
                                    >
                                        <Avatar className="profilePicture" alt="Travis Howard" src={avatar} />
                                        <StyledButton>
                                            Upload Profile Picture
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    color: "#64748B",
                                                    marginLeft: "5px"
                                                }}
                                            >
                                                valid Formats (PNG, JPG)
                                            </Typography>
                                            <input
                                                data-testid="fileUpload"
                                                type="file"
                                                accept="image/png, image/jpg"
                                                onChange={(event) => {
                                                    const file = event.target.files?.[0];
                                                    this.setState({ fileName: file?.name || "profile image" })
                                                    file && setFieldValue("profile_image", file);
                                                }}
                                            />
                                        </StyledButton>
                                    </Box>
                                )
                            }
                        <label className="labelTag">Full name</label>
                        <TextField
                            type="text"
                            name="user_name"
                            data-testid="username"
                            placeholder="Your full name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            {...this.getTextFieldErrorProps('user_name', touched, errors)}
                            helperText={touched.user_name && errors.user_name}
                            sx={{ marginBottom: "15px", ".MuiOutlinedInput-root":{borderRadius:'8px'}}}
                        />
                        <label className="labelTag">Email</label>
                        <TextField
                            data-testid="email"
                            type="email"
                            name="email"
                            placeholder="Enter your email address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            {...this.getTextFieldErrorProps('email', touched, errors)}
                            sx={{ marginBottom: "15px", ".MuiOutlinedInput-root":{borderRadius:'8px'}}}
                        />
                        <label className="labelTag">Date ofBirth</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={values.date_of_birth ? dayjs(values.date_of_birth, 'DD-MM-YYYY') : null}
                                    format="DD-MM-YYYY" 
                                    data-test-id="date__picker"
                                    onChange={(newValue) => {
                                        const formattedDate = newValue ? dayjs(newValue).format('DD-MM-YYYY') : null;
                                        setFieldValue('date_of_birth', formattedDate);
                                    }}
                                    slots={{
                                        textField: (params:any) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                onBlur={handleBlur('date_of_birth')}
                                                placeholder="Your Birth Day"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarTodayIcon sx={{ color: '#9c27b0' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    marginBottom: '15px',
                                                        cursor:"pointer",
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '8px',
                                                        '& fieldset': {
                                                            borderColor: '#CBD5E1',
                                                        },
                                                    },
                                                }}
                                                {...this.getTextFieldErrorProps('date_of_birth', touched, errors)}
                                            />
                                        ),
                                    }}
                                    views={['year', 'month', 'day']} // Enable year, month, day view
                                />
                            </LocalizationProvider>
                        <label className="labelTag">Password</label>
                        <TextField
                            data-testid="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            placeholder="Your password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={(e) => {
                                handleBlur(e);
                                this.setState({isFocused:false});
                            }}
                            onFocus={() => this.setState({isFocused:true})}
                            fullWidth
                            {...this.getTextFieldErrorProps('password', touched, errors)}
                            sx={{ marginBottom: "15px", ".MuiOutlinedInput-root":{borderRadius:'8px'}}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.handleTogglePassword} edge="end">
                                            {this.state.showPassword ? <Visibility />  : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <this.PasswordValidationChecklist
                            password={values.password}
                            visible={this.state.isFocused}
                        />
                        <label className="labelTag">Confirm Password</label>
                        <TextField
                            type={this.state.showPassword ? 'text' : 'password'}
                            placeholder="Confirm your password"
                            name="password_confirmation"
                            value={values.password_confirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            {...this.getTextFieldErrorProps('password_confirmation', touched, errors)}
                            sx={{ marginBottom: "15px", ".MuiOutlinedInput-root":{borderRadius:'8px'}}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.handleTogglePassword} edge="end">
                                            {this.state.showPassword ? <Visibility />  : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box className="checkBox">
                            <div>
                                <Checkbox checked={values.picked}
                                    onChange={handleChange}
                                    onBlur={handleBlur} name="picked" value="rememberMe"
                                    sx={{ '&.MuiCheckbox-root':{padding:0},'&:hover': { bgcolor: 'transparent' } }}
                                    disableRipple
                                    color="default"
                                    checkedIcon={<BpCheckedIcon />}
                                    icon={<BpIcon />}
                                    inputProps={{ 'aria-label': 'Checkbox demo' }}
                                    {...this.props}
                                     />
                            </div>
                            <div className="options">
                            I agree with <Link className="forgotField" to={"/PrivacyPolicy"}>Privacy Policy</Link> and <Link className="forgotField" to={"/TermsConditionsDetail"}>Terms and Conditions</Link>
                            </div>
                        </Box>
                        <button type="submit" className="loginBtn" data-testid="submit" style={{
                            background: this.getButtonGradient(isValid, dirty),
                        }} >
                            Sign up
                        </button>
                    </form>
                )}
            </Formik>
            <Typography className="alreadyAccountHeading" >Already have an account? <Link to={"/EmailAccountLoginBlock"} className="loginNavigation">Log in</Link></Typography>
        </Box>
        <Box className="loginBg"><img src={leaf} alt="image" /></Box>
    </StyledWrapper>
    );
  // Customizable Area End
  }

}


// Customizable Area Start
const StyledWrapper = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    overflowX:"hidden",
    height:"892px",
    '@media (max-width: 900px)': {
        flexDirection: "column",
        marginTop: "30px",
        alignItems: "end"
    },
    "& .heading": {
        fontSize: "24px",
        fontWeight: "700",
        color: "#000000",
        marginBottom: "10px"
    },
    "& .formContainer": {
        display: "flex",
        flexDirection: "column",
        width: "360px"
    },
    "& .labelTag": {
        fontSize: "16px",
        fontWeight: "700",
        color: "#334155",
        marginBottom: "5px",
        fontFamily:"Inter, sans-serif"
    },
    "& .inputTags": {
        paddingLeft: "5px",
        height: "56px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .checkBox": {
        height: "45px",
        display: "flex",
        alignItems: "flex-start"
    },
    "& .options": {
        fontSize: "16px",
        fontWeight: "400",
        color: "#0F172A",
        marginLeft: "5px",
        fontFamily:"Inter, sans-serif"
    },
    "& .forgotField": {
        fontSize: "16px",
        fontWeight: "600",
        color: "#6200EA",
        textDecoration: "none",
    },
    "& .loginBtn": {
        border: "none",
        marginTop: "8px",
        borderRadius: "8px",
        height: "56px",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: "700"
    },
    "& .alreadyAccountHeading": {
        fontSize: "16px",
        fontWeight: "400",
        color: "#0F172A",
        marginTop: "10px"
    },
    "& .loginNavigation": {
        fontWeight: "700",
        fontSize: "16px",
        color: "#6200EA",
        textDecoration: "none"
    },
    "& .loginBg": {
        width: "360px",
    },
    "& .fileUpload": {
        height: "32px",
        display: "flex",
        alignItems: "center",
        padding: "6px 12px",
        backgroundColor:"#F6F0FF",
        borderRadius:"8px",
        marginBottom:"12px"
    },
    "& .uploadName":{
        height: "32px",
        border:"1px solid #5500CC",
        borderRadius:"8px",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        padding:"6px 12px",
        marginBottom:"10px"
    },
    "& .profilePicture": {
        height: "23px",
        width: "23px",
        border: "0.5px solid #8833FF",
    },
    "& .errorMessage": {
        height: "42px",
        backgroundColor: "#FEE2E2",
        borderRadius: "4px",
        fontSize: "14px",
        fontWeight: "400",
        color: "#DC2626",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderLeft: "4px solid #DC2626",
        marginBottom:"10px"
    }

});

const StyledButton = styled(Button)({
    color: "#000000",
    fontSize: "14px",
    textTransform: "none",
    display:"flex",
    fontWeight: "400",
    textAlign: "center",
    cursor: "pointer",
    "& input": {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        cursor: "pointer",
    },
});

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 5,
    width: 18,
    height: 18,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#6200EA',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
});
  // Customizable Area End

